import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Login from './Components/Login';
import Accounts from './Components/Accounts';
import Dashboard from './Components/Dashboard';
import LocalBusinessFinder from './Components/LocalBusinessFinder/LocalBusinessFinder';
import SAMTool from './Components/SAMTool'; // New component
import SEORankings from './Components/SEORankings'; // New component
import KeywordPlanner from './Components/KeywordPlanner'; // New component
import AddAccountForm from './Components/AddAccountForm';
import EditAccountForm from './Components/EditAccountForm';

function CatchAllRedirect({ isAuthenticated }) {
  const location = useLocation();

  // Exclude paths starting with '/api/'
  if (location.pathname.startsWith('/api/')) {
    // Do not render anything; let the server handle it
    return null;
  }

  // Redirect to the appropriate page
  return <Navigate to={isAuthenticated ? '/dashboard' : '/login'} replace />;
}

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('token')
  );

  useEffect(() => {
    const handleAuthChange = () => {
      setIsAuthenticated(!!localStorage.getItem('token'));
    };

    window.addEventListener('authChange', handleAuthChange);

    return () => {
      window.removeEventListener('authChange', handleAuthChange);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/accounts"
          element={
            isAuthenticated ? <Accounts /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/dashboard"
          element={
            isAuthenticated ? <Dashboard /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/local-rankings"
          element={
            isAuthenticated ? (
              <LocalBusinessFinder />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/sam-tool"
          element={
            isAuthenticated ? <SAMTool /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/keyword-planner"
          element={
            isAuthenticated ? (
              <KeywordPlanner />
            ) : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/seo-rankings"
          element={
            isAuthenticated ? (
              <SEORankings />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="/add-account" element={<AddAccountForm />} />
        <Route path="/edit-account" element={<EditAccountForm />} />
        {/* Custom Catch-All Route */}
        <Route
          path="*"
          element={<CatchAllRedirect isAuthenticated={isAuthenticated} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
