import React, { useState, useEffect, useMemo } from 'react';
import Papa from 'papaparse';
import csvFile from '../Components/Dictionary-SAM.csv';

const Dictionary = () => {
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 45;

  // Sorting State
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Search State
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    Papa.parse(csvFile, {
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        setFields(results.data);
        setLoading(false);
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        setError(error.message);
        setLoading(false);
      }
    });
  }, []);

  // Sorting Handler
  const handleSort = (columnKey) => {
    let direction = 'asc';
    if (sortConfig.key === columnKey && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === columnKey && sortConfig.direction === 'desc') {
      direction = null;
      columnKey = null;
    }
    setSortConfig({ key: columnKey, direction });
  };

  // Memoized Sorted and Filtered Fields
  const sortedFilteredFields = useMemo(() => {
    let filteredData = fields;

    // Filter based on search query
    if (searchQuery) {
      const lowerSearch = searchQuery.toLowerCase();
      filteredData = filteredData.filter(field =>
        Object.values(field).some(
          value =>
            value &&
            value.toString().toLowerCase().includes(lowerSearch)
        )
      );
    }

    // Sort based on sortConfig
    if (sortConfig.key && sortConfig.direction) {
      filteredData = [...filteredData].sort((a, b) => {
        const aVal = a[sortConfig.key] ? a[sortConfig.key].toString().toLowerCase() : '';
        const bVal = b[sortConfig.key] ? b[sortConfig.key].toString().toLowerCase() : '';

        if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }

    return filteredData;
  }, [fields, sortConfig, searchQuery]);

  // Calculate Total Pages
  const totalPages = Math.ceil(sortedFilteredFields.length / itemsPerPage);

  // Get Current Page Data
  const currentPageData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedFilteredFields.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedFilteredFields, currentPage, itemsPerPage]);

  // Pagination Handlers
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goToPrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const goToNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const goToLast = () => {
    setCurrentPage(totalPages);
  };

  // Render Sorting Arrow
  const renderSortArrow = (columnKey) => {
    if (sortConfig.key !== columnKey) return null;
    if (sortConfig.direction === 'asc') {
      return (
        <svg
          className="w-4 h-4 ml-1 inline"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
        </svg>
      );
    }
    if (sortConfig.direction === 'desc') {
      return (
        <svg
          className="w-4 h-4 ml-1 inline"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      );
    }
    return null;
  };

  // Function to generate pagination range
  const getPaginationRange = () => {
    const range = [];
    const windowSize = 3; // Number of page buttons to show

    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(currentPage + 1, totalPages);

    // Adjust start and end if near the boundaries
    if (currentPage === 1) {
      endPage = Math.min(windowSize, totalPages);
    } else if (currentPage === totalPages) {
      startPage = Math.max(totalPages - (windowSize - 1), 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      range.push(i);
    }

    return range;
  };

  if (loading) {
    return <div className="text-center mt-8">Loading data...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">Error: {error}</div>;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
      {/* Header and Search Bar */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-4 space-y-2 md:space-y-0">
        <h3 className="text-lg font-semibold">Data Dictionary</h3>

        {/* Search Bar */}
        <div className="relative w-full md:w-1/3">
          <input
            type="text"
            placeholder="Search..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1); // Reset to first page on search
            }}
          />
          <svg
            className="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-4.35-4.35m0 0A7.5 7.5 0 1110.5 3a7.5 7.5 0 018.15 13.65z" />
          </svg>
        </div>
      </div>

      {sortedFilteredFields.length === 0 ? (
        <div className="text-center">No data available</div>
      ) : (
        <>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="w-full text-sm table-auto">
              <thead>
                <tr className="bg-gray-800 text-white">
                  {/* Category Column */}
                  <th
                    className="p-2 text-left cursor-pointer select-none"
                    onClick={() => handleSort('Category')}
                    aria-sort={
                      sortConfig.key === 'Category'
                        ? sortConfig.direction === 'asc'
                          ? 'ascending'
                          : 'descending'
                        : 'none'
                    }
                  >
                    Category {renderSortArrow('Category')}
                  </th>

                  {/* Field Name Column */}
                  <th
                    className="p-2 text-left cursor-pointer select-none w-1/6"
                    onClick={() => handleSort('FieldName')}
                    aria-sort={
                      sortConfig.key === 'FieldName'
                        ? sortConfig.direction === 'asc'
                          ? 'ascending'
                          : 'descending'
                        : 'none'
                    }
                  >
                    Field Name {renderSortArrow('FieldName')}
                  </th>

                  {/* Description Column */}
                  <th
                    className="p-2 text-left cursor-pointer select-none"
                    onClick={() => handleSort('Description')}
                    aria-sort={
                      sortConfig.key === 'Description'
                        ? sortConfig.direction === 'asc'
                          ? 'ascending'
                          : 'descending'
                        : 'none'
                    }
                  >
                    Description {renderSortArrow('Description')}
                  </th>

                  {/* Data Source Column */}
                  <th
                    className="p-2 text-left cursor-pointer select-none"
                    onClick={() => handleSort('DataSource')}
                    aria-sort={
                      sortConfig.key === 'DataSource'
                        ? sortConfig.direction === 'asc'
                          ? 'ascending'
                          : 'descending'
                        : 'none'
                    }
                  >
                    Data Source {renderSortArrow('DataSource')}
                  </th>

                  {/* Data Source Variable Name Column */}
                  <th
                    className="p-2 text-left cursor-pointer select-none"
                    onClick={() => handleSort('DataSourceVariableName')}
                    aria-sort={
                      sortConfig.key === 'DataSourceVariableName'
                        ? sortConfig.direction === 'asc'
                          ? 'ascending'
                          : 'descending'
                        : 'none'
                    }
                  >
                    Data Source Variable Name {renderSortArrow('DataSourceVariableName')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map((field, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                    <td className="p-2">{field.Category}</td>
                    <td className="p-2 font-medium max-w-xs truncate" title={field.FieldName}>
                      {field.FieldName}
                    </td>
                    <td className="p-2 max-w-md truncate" title={field.Description}>
                      {field.Description}
                    </td>
                    <td className="p-2 max-w-xs truncate" title={field.DataSource}>
                      {field.DataSource}
                    </td>
                    <td className="p-2 max-w-xs truncate" title={field.DataSourceVariableName}>
                      {field.DataSourceVariableName}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4">
            {/* Showing Range */}
            <div className="text-sm text-gray-700">
              Showing{' '}
              {sortedFilteredFields.length === 0
                ? 0
                : (currentPage - 1) * itemsPerPage + 1}{' '}
              to{' '}
              {Math.min(currentPage * itemsPerPage, sortedFilteredFields.length)} of{' '}
              {sortedFilteredFields.length} entries
            </div>

            {/* Pagination Buttons */}
            <div className="flex items-center space-x-2">
              {/* Previous Button */}
              <button
                onClick={goToPrevious}
                disabled={currentPage === 1}
                aria-label="Go to previous page"
                className={`px-3 py-1 rounded ${
                  currentPage === 1
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-gray-800 text-white hover:bg-gray-700'
                }`}
              >
                Previous
              </button>

              {/* Page Numbers */}
              {getPaginationRange().map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => goToPage(pageNumber)}
                  className={`px-3 py-1 rounded ${
                    currentPage === pageNumber
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-800 text-white hover:bg-gray-700'
                  }`}
                >
                  {pageNumber}
                </button>
              ))}

              {/* Next Button */}
              <button
                onClick={goToNext}
                disabled={currentPage === totalPages}
                aria-label="Go to next page"
                className={`px-3 py-1 rounded ${
                  currentPage === totalPages
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-gray-800 text-white hover:bg-gray-700'
                }`}
              >
                Next
              </button>

              {/* Last Button */}
              <button
                onClick={goToLast}
                disabled={currentPage === totalPages}
                aria-label="Go to last page"
                className={`px-3 py-1 rounded ${
                  currentPage === totalPages
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-gray-800 text-white hover:bg-gray-700'
                }`}
              >
                Last
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dictionary;
