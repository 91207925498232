import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  MapPin,
  DollarSign,
  Home,
  Users,
  Star,
  Key,
  Target,
} from 'lucide-react';
import MainLayout from './MainLayout';

const Dashboard = () => {
  const [accountData, setAccountData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const selectedAccountId = localStorage.getItem('selectedAccountId');
    if (selectedAccountId) {
      fetchAccountData(selectedAccountId);
    } else {
      navigate('/accounts');
    }
  }, [navigate]);

  const fetchAccountData = async (accountId) => {
    // Replace this with your actual API call
    // For now, we'll use the mock data
    setAccountData({
      name: '7 Day Kitchens - Kitchen Remodel',
      location: 'Charlotte NC, 28209',
      code: '7DAYKITCH12342',
      type: 'AUDITS',
      population: 250000,
      medIncome: 88267,
      medHomeValue: 225589,
      rankings: [
        { name: 'Bathroom Remodeling', value: 5.6, total: 10.5, rank: 3 },
        { name: 'Kitchen Remodeling', value: 8.2, total: 10.0, rank: 1 },
        { name: 'Home Remodeling', value: 6.5, total: 10.0, rank: 2 },
      ],
      keywords: 25,
      rankingTop10: 10,
      estReach: 5000,
    });
  };

  if (!accountData) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center h-screen">
          <div className="text-2xl font-semibold text-gray-600">Loading...</div>
        </div>
      </MainLayout>
    );
  }

  const chartData = accountData.rankings.map((ranking) => ({
    name: ranking.name,
    value: ranking.value,
  }));

  return (
    <MainLayout>
      <div className="container mx-auto space-y-8 p-4">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">{accountData.name}</h1>
        
        {/* Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Location Card */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center justify-between pb-4 border-b">
              <span className="text-sm font-semibold text-gray-600">Location</span>
              <MapPin className="h-5 w-5 text-gray-400" />
            </div>
            <div className="mt-4">
              <div className="text-xl font-semibold text-gray-800">{accountData.location}</div>
              <p className="text-sm text-gray-500">Code: {accountData.code}</p>
            </div>
          </div>
          
          {/* Population Card */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center justify-between pb-4 border-b">
              <span className="text-sm font-semibold text-gray-600">Population</span>
              <Users className="h-5 w-5 text-gray-400" />
            </div>
            <div className="mt-4">
              <div className="text-xl font-semibold text-gray-800">
                {accountData.population.toLocaleString()}
              </div>
              <p className="text-sm text-gray-500">Total residents</p>
            </div>
          </div>
          
          {/* Median Income Card */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center justify-between pb-4 border-b">
              <span className="text-sm font-semibold text-gray-600">Median Income</span>
              <DollarSign className="h-5 w-5 text-gray-400" />
            </div>
            <div className="mt-4">
              <div className="text-xl font-semibold text-gray-800">
                ${accountData.medIncome.toLocaleString()}
              </div>
              <p className="text-sm text-gray-500">Per household</p>
            </div>
          </div>
          
          {/* Median Home Value Card */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center justify-between pb-4 border-b">
              <span className="text-sm font-semibold text-gray-600">Median Home Value</span>
              <Home className="h-5 w-5 text-gray-400" />
            </div>
            <div className="mt-4">
              <div className="text-xl font-semibold text-gray-800">
                ${accountData.medHomeValue.toLocaleString()}
              </div>
              <p className="text-sm text-gray-500">Average property value</p>
            </div>
          </div>
        </div>

        {/* Remodeling Rankings Chart */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="border-b pb-4">
            <h2 className="text-lg font-semibold text-gray-800">Remodeling Rankings</h2>
          </div>
          <div className="mt-6 h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#6366F1" radius={[4, 4, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Ranking Details and Additional Metrics */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Ranking Details */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="border-b pb-4">
              <h2 className="text-lg font-semibold text-gray-800">Ranking Details</h2>
            </div>
            <div className="mt-6 space-y-6">
              {accountData.rankings.map((ranking, index) => (
                <div key={index} className="space-y-2">
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">{ranking.name}</span>
                    <span className="text-gray-500">Rank: {ranking.rank}</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-indigo-600 h-2 rounded-full"
                      style={{ width: `${(ranking.value / ranking.total) * 100}%` }}
                    ></div>
                  </div>
                  <p className="text-sm text-gray-500">
                    {ranking.value} out of {ranking.total}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Additional Metrics */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="border-b pb-4">
              <h2 className="text-lg font-semibold text-gray-800">Additional Metrics</h2>
            </div>
            <div className="mt-6 space-y-4">
              <div className="flex items-center">
                <Key className="mr-2 h-5 w-5 text-gray-400" />
                <span className="font-medium text-gray-700">Keywords:</span>
                <span className="ml-auto text-gray-800">{accountData.keywords}</span>
              </div>
              <div className="flex items-center">
                <Star className="mr-2 h-5 w-5 text-gray-400" />
                <span className="font-medium text-gray-700">Ranking in Top 10:</span>
                <span className="ml-auto text-gray-800">{accountData.rankingTop10}</span>
              </div>
              <div className="flex items-center">
                <Target className="mr-2 h-5 w-5 text-gray-400" />
                <span className="font-medium text-gray-700">Estimated Reach:</span>
                <span className="ml-auto text-gray-800">
                  {accountData.estReach.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Dashboard;