import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaHome, FaSearch, FaUsers, FaArchive } from 'react-icons/fa';
import logo from './Assets/logo-local-empire.png';

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const response = await fetch('/api/accounts');
      const data = await response.json();
      if (data.success) {
        setAccounts(data.data);
      } else {
        console.error('Failed to fetch accounts:', data.error);
        setError('Failed to fetch accounts.');
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
      setError('Error fetching accounts.');
    }
  };

  const handleAddAccount = () => {
    navigate('/add-account');
  };

  const handleAccountClick = (accountId) => {
    localStorage.setItem('selectedAccountId', accountId);
    navigate('/dashboard');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('authChange'));
    navigate('/login');
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Top Bar */}
      <div className="bg-[#141b30] text-white p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <img src={logo} alt="Local Empire Logo" className="h-8 mr-2" />
          
          </div>
          <button
            onClick={handleLogout}
            className="flex items-center px-3 py-2 rounded hover:bg-[#1f2b4d] transition-colors duration-200"
          >
            <span className="mr-2">Logout</span>
            <FaSignOutAlt />
          </button>
        </div>
      </div>

      {/* Navigation Bar */}
      <div className="bg-gray-200 text-gray-700 py-2 px-4">
        <div className="container mx-auto flex items-center">
          <FaHome className="mr-2" />
          <span>All Accounts</span>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">Accounts</h2>
        
        {/* Search and Filters */}
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <select className="mr-4 p-2 border border-gray-300 rounded-md bg-white">
              <option>View All</option>
            </select>
            <div className="relative">
              <input
                type="text"
                placeholder="Name, City, Zip, or Unique Code"
                className="p-2 pl-10 w-64 border border-gray-300 rounded-md"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
          </div>
          <div className="flex items-center">
            <button className="flex items-center px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-4 hover:bg-gray-400 transition-colors duration-200">
              <FaUsers className="mr-2" />
              Account Groups
            </button>
            <button
              onClick={handleAddAccount}
              className="flex items-center px-4 py-2 bg-[#c1975f] text-white rounded-md hover:bg-[#a17c4a] transition-colors duration-200"
            >
              + Add Account
            </button>
          </div>
        </div>

        {/* Accounts List */}
        {error && <div className="text-red-500 mb-4">{error}</div>}
        {accounts.length > 0 ? (
          <div className="space-y-4">
            {accounts.map((account) => (
              <div
                key={account._id}
                className="bg-white rounded-lg shadow border border-gray-200 p-4 hover:border-[#c1975f] transition-colors duration-200 cursor-pointer"
                onClick={() => handleAccountClick(account._id)}
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-start">
                    <div className="mr-8">
                      <h3 className="text-lg font-semibold flex items-center">
                        <span className="mr-2">👑</span>
                        {account.name}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {account.city}, {account.state} {account.zip}
                      </p>
                      <p className="text-sm text-gray-600">{account.uniqueCode}</p>
                      <p className="text-sm text-gray-600 mt-2">👤 AUDITS</p>
                    </div>
                    <div className="mr-8">
                      <p className="text-sm"><strong>Population:</strong> {account.population}</p>
                      <p className="text-sm"><strong>Med. Income:</strong> ${account.medianIncome}</p>
                      <p className="text-sm"><strong>Med. Home Value:</strong> ${account.medianHomeValue}</p>
                      <button className="text-sm text-blue-600 mt-2">Location ›</button>
                    </div>
                    <div>
                      <p className="text-sm"><strong>Keywords:</strong> {account.keywords}</p>
                      <p className="text-sm"><strong>Ranking in top 10:</strong> {account.rankingTop10}</p>
                      <p className="text-sm"><strong>Est. reach:</strong> {account.estimatedReach}</p>
                      <button className="text-sm text-blue-600 mt-2">Keywords ›</button>
                    </div>
                  </div>
                  <div className="flex flex-col items-end">
                    <button className="px-4 py-2 bg-green-100 text-green-800 rounded-md mb-2 hover:bg-green-200 transition-colors duration-200">
                      Overview ›
                    </button>
                    <button className="flex items-center px-4 py-2 border border-gray-300 rounded-md bg-white text-gray-700 hover:bg-gray-100 transition-colors duration-200">
                      <FaArchive className="mr-2" />
                      Archive
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-600">No accounts available. Please add an account.</p>
        )}
      </div>
    </div>
  );
};

export default Accounts;