// /localbusiness-seo/frontend/src/components/Login.jsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLock, FaSpinner } from 'react-icons/fa';
import { motion } from 'framer-motion';
import logo from './Assets/logo-local-empire.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Attempting login with email:', email);
    // Removed API_URL since we're using relative paths
    setError('');
    setIsLoading(true);

    try {
      // Use relative path for the API endpoint
      const endpoint = `/api/login`;
      console.log('Sending POST request to:', endpoint);

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      console.log('Received response:', response);

      const contentType = response.headers.get('content-type');
      console.log('Response Content-Type:', contentType);

      const text = await response.text();
      console.log('Response Text:', text);

      let data;
      try {
        data = JSON.parse(text);
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError);
        setError('Invalid response from server.');
        return;
      }

      console.log('Parsed response data:', data);

      if (response.ok) {
        console.log('Login successful, token:', data.token);
        localStorage.setItem('token', data.token);
        window.dispatchEvent(new Event('authChange'));
        navigate('/accounts');
      } else {
        console.error('Login failed:', response.status, response.statusText, data);
        setError(data.message || `Login failed: ${response.status} ${response.statusText}`);
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full bg-white rounded-lg shadow-md p-8 space-y-6"
      >
        <div className="flex items-center justify-center">
          <motion.img
            src={logo}
            alt="Local Empire Logo"
            className="h-12 w-auto"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 260, damping: 20 }}
          />
        </div>
        <h2 className="text-2xl font-bold text-center text-gray-800">Sign in to your account</h2>
        {error && <div className="text-red-500 text-center">{error}</div>}
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email Address</label>
            <input
              type="email"
              required
              className="mt-1 p-3 w-full border border-gray-300 rounded-md"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="you@example.com"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              required
              className="mt-1 p-3 w-full border border-gray-300 rounded-md"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="••••••••"
            />
          </div>
          <motion.button
            type="submit"
            className="w-full flex items-center justify-center px-4 py-3 bg-[#141b30] text-white rounded-md hover:bg-[#1f2b4d] transition-colors duration-200"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={isLoading}
          >
            {isLoading ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              <FaLock className="mr-2" />
            )}
            {isLoading ? 'Signing In...' : 'Sign In'}
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
};

export default Login;
