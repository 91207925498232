import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { FaSearch, FaDownload, FaHome, FaUsers } from 'react-icons/fa';
import MapView from './MapView';
import { ClipLoader } from 'react-spinners';
import MainLayout from '../MainLayout';
import logo from '../Assets/logo-local-empire.png';

const LocalBusinessFinder = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { account } = location.state || {};

  const [keyword, setKeyword] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pointDistance, setPointDistance] = useState(3);
  const [notification, setNotification] = useState(null);
  const [city, setCity] = useState('');
  const [accountName, setAccountName] = useState('');
  const [centerCoordinates, setCenterCoordinates] = useState(null);

  useEffect(() => {
    const fetchAccountName = async () => {
      try {
        const response = await fetch('/api/accounts/current');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.success && data.account) {
          setAccountName(data.account.name);
        } else {
          throw new Error(data.error || 'Failed to fetch account name');
        }
      } catch (error) {
        console.error('Failed to fetch account name:', error);
        showNotification('Failed to fetch account name. Please try again later.', 'error');
      }
    };

    fetchAccountName();
  }, []);

  const showNotification = useCallback((message, type = 'success') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 5000);
  }, []);

  const handleGridSearch = useCallback(async () => {
    if (!keyword) {
      showNotification('Please enter a keyword before searching.', 'error');
      return;
    }

    if (!city) {
      showNotification('Please enter a city before searching.', 'error');
      return;
    }

    if (!accountName) {
      showNotification('Account name not available. Please try again.', 'error');
      return;
    }

    setLoading(true);
    setResults([]);

    try {
      console.log('Starting grid search process...');
      
      const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      console.log('Google Maps API Key:', apiKey ? 'Set' : 'Not set');
    
      if (!apiKey) {
        throw new Error('Google Maps API key is not set');
      }
    
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(city)}&key=${apiKey}`;
      console.log('Geocoding URL:', geocodeUrl.replace(apiKey, 'API_KEY_REDACTED'));
    
      console.log('Sending geocoding request...');
      const geocodeResponse = await fetch(geocodeUrl);
      console.log('Geocode response status:', geocodeResponse.status);
    
      if (!geocodeResponse.ok) {
        throw new Error(`HTTP error! status: ${geocodeResponse.status}`);
      }
    
      console.log('Parsing geocode response...');
      const geocodeData = await geocodeResponse.json();
      console.log('Geocode response data:', JSON.stringify(geocodeData, null, 2));
    
      if (geocodeData.status !== 'OK') {
        throw new Error(`Geocoding failed. Status: ${geocodeData.status}, Error message: ${geocodeData.error_message || 'None'}`);
      }
    
      if (!geocodeData.results || geocodeData.results.length === 0) {
        throw new Error('No results found for the given city');
      }
    
      const location = geocodeData.results[0].geometry.location;
      const lat = location.lat;
      const lng = location.lng;
      console.log(`Geocoded coordinates: Lat ${lat}, Lng ${lng}`);

      setCenterCoordinates({ latitude: lat, longitude: lng });
    
      const searchBody = {
        keyword,
        targetBusinessName: accountName,
        centerLat: lat,
        centerLng: lng,
        pointDistance,
        city,
      };
    
      console.log('Search request body:', JSON.stringify(searchBody, null, 2));
    
      console.log('Sending grid search request...');
      const response = await fetch('/api/places/search', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(searchBody),
      });
    
      console.log('Grid search response status:', response.status);
    
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Search failed with status ${response.status}: ${errorText}`);
        throw new Error(`Failed to perform grid search: ${errorText}`);
      }
    
      console.log('Parsing grid search response...');
      const data = await response.json();
      console.log('Grid search response data:', JSON.stringify(data, null, 2));
    
      if (!data.data) {
        throw new Error('Unexpected response format from server');
      }
    
      console.log(`Received ${data.data.length} results from grid search`);
      setResults(data.data);
      showNotification('Grid search completed successfully!');
    } catch (err) {
      console.error('Search error:', err);
      showNotification(`Error: ${err.message}`, 'error');
    } finally {
      setLoading(false);
      console.log('Grid search process completed.');
    }
    
  }, [keyword, city, pointDistance, showNotification, accountName]);
    
  const averageResults = useMemo(() => {
    console.log('Calculating average results...');
    if (results.length === 0) {
      console.log('No results to calculate averages from.');
      return [];
    }
    
    const businessRankings = results.reduce((acc, point) => {
      point.results.forEach((business, index) => {
        const name = business.title || business.name;
        if (!acc[name]) {
          acc[name] = { totalRank: 0, count: 0 };
        }
        acc[name].totalRank += index + 1;
        acc[name].count += 1;
      });
      return acc;
    }, {});
    
    console.log('Business rankings:', businessRankings);
    
    const averagedResults = Object.entries(businessRankings)
      .map(([name, data]) => ({
        name,
        averageRank: data.totalRank / data.count
      }))
      .sort((a, b) => a.averageRank - b.averageRank)
      .slice(0, 20);
    
    console.log('Top 20 averaged results:', averagedResults);
    return averagedResults;
  }, [results]);

  const csvData = useMemo(() => {
    return results.flatMap((point, index) => 
      point.results.map(business => ({
        'Grid Point': index + 1,
        'Grid Latitude': point.lat,
        'Grid Longitude': point.lng,
        'Business Name': business.title || business.name,
        'Business Address': business.address,
        'Business Phone': business.phone,
        'Business Website': business.website,
        'Business Rating': business.rating,
        'Keyword': keyword,
        'Target Business': accountName || '',
        'Target Business Ranking': point.ranking || 'N/A'
      }))
    );
  }, [results, keyword, accountName]);

  return (
    <MainLayout>
      <div className="flex flex-col min-h-screen bg-transparent">
       

        {/* Main Content */}
        <div className="flex-grow container mx-auto px-4 py-8">
          <h2 className="text-2xl font-bold mb-6">Grid Search for {accountName || 'Current Account'}</h2>
          
          <SearchPanel
            keyword={keyword}
            setKeyword={setKeyword}
            city={city}
            setCity={setCity}
            pointDistance={pointDistance}
            setPointDistance={setPointDistance}
            handleGridSearch={handleGridSearch}
            loading={loading}
          />

          {results.length > 0 && (
            <SearchResults 
              results={results} 
              averageResults={averageResults} 
              csvData={csvData} 
              businessDetails={{ 
                businessName: accountName, 
                latitude: centerCoordinates?.latitude,
                longitude: centerCoordinates?.longitude,
                city: city,
                pointDistance: pointDistance
              }}
              keyword={keyword}
            />
          )}
        </div>
      </div>
      <Notification notification={notification} />
    </MainLayout>
  );
};

const Notification = ({ notification }) => (
  notification && (
    <div className={`fixed bottom-4 right-4 px-4 py-2 rounded-md text-white shadow-lg ${notification.type === 'error' ? 'bg-red-500' : 'bg-green-500'}`}>
      {notification.message}
    </div>
  )
);

const SearchPanel = ({
  keyword,
  setKeyword,
  city,
  setCity,
  pointDistance,
  setPointDistance,
  handleGridSearch,
  loading
}) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-8">
    <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
      <input
        type="text"
        className="flex-grow p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#c1975f] focus:border-transparent text-gray-900 mb-4 md:mb-0"
        placeholder="Enter keyword for search"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <input
        type="text"
        className="flex-grow p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#c1975f] focus:border-transparent text-gray-900 mb-4 md:mb-0"
        placeholder="Enter city"
        value={city}
        onChange={(e) => setCity(e.target.value)}
      />
      <input
        type="number"
        className="w-32 p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#c1975f] focus:border-transparent text-gray-900 mb-4 md:mb-0"
        placeholder="Distance (mi)"
        value={pointDistance}
        onChange={(e) => setPointDistance(Math.max(1, parseInt(e.target.value) || 1))}
        min="1"
      />
      <button 
        className="w-full md:w-auto p-3 bg-[#c1975f] text-white rounded-md hover:bg-[#a17c4a] focus:outline-none focus:ring-2 focus:ring-[#c1975f] focus:ring-opacity-50 transition duration-200 ease-in-out flex items-center justify-center"
        onClick={handleGridSearch}
        disabled={loading}
      >
        {loading ? (
          <>
            <ClipLoader size={20} color={"#fff"} loading={loading} className="mr-2" />
            Searching...
          </>
        ) : (
          <>
            <FaSearch className="mr-2" />
            Perform Grid Search
          </>
        )}
      </button>
    </div>
  </div>
);

const SearchResults = ({ results, averageResults, csvData, businessDetails, keyword }) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
      <h3 className="text-xl font-semibold text-gray-800 mb-4 md:mb-0">Grid Search Results</h3>
      <CSVLink
        data={csvData}
        filename={"grid_search_results.csv"}
        className="px-4 py-2 bg-[#c1975f] text-white rounded-md hover:bg-[#a17c4a] focus:outline-none focus:ring-2 focus:ring-[#c1975f] focus:ring-opacity-50 transition duration-200 ease-in-out flex items-center text-sm"
      >
        <FaDownload className="mr-2" />
        Export CSV
      </CSVLink>
    </div>

    <div className="mb-8">
      <h4 className="text-lg font-semibold mb-4 text-gray-800">Map View</h4>
      <MapView 
        results={results} 
        targetBusinessName={businessDetails?.businessName}
        businessLocation={{
          latitude: businessDetails?.latitude,
          longitude: businessDetails?.longitude
        }}
        city={businessDetails?.city}
        pointDistance={businessDetails?.pointDistance}
        searchKeyword={keyword}
      />
    </div>

    <div className="mb-8">
      <h4 className="text-lg font-semibold mb-4 text-gray-800">Average Results</h4>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Rank</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Business Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Average Ranking</th>
            </tr>
          </thead>
          <tbody className="text-gray-800">
            {averageResults.map((business, index) => (
              <tr key={business.name} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="px-6 py-3 whitespace-nowrap">{index + 1}</td>
                <td className={`px-6 py-3 whitespace-nowrap ${business.name === businessDetails?.businessName ? 'font-semibold text-[#c1975f]' : ''}`}>
                  {business.name}
                </td>
                <td className="px-6 py-3 whitespace-nowrap">{business.averageRank.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default LocalBusinessFinder;