// src/components/SAMTool.jsx
import React, { useState } from 'react';
import { MapPin } from 'lucide-react';
import MainLayout from './MainLayout';
import Step2 from './Step2';
import Step3 from './Step3';
import Description from './Description';  
import Dictionary from './Dictionary';

// Import Google Maps components
import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';

const SAMTool = () => {
  // State to capture ZIP code and radius inputs
  const [zip, setZip] = useState('');
  const [radius, setRadius] = useState('');
  
  // State to hold search parameters after user initiates a search
  const [searchParams, setSearchParams] = useState(null);
  
  // State for error messages
  const [errorMessage, setErrorMessage] = useState('');

  // Map state
  const [center, setCenter] = useState({ lat: 37.0902, lng: -95.7129 }); // Default center (USA)
  const [mapMarkers, setMapMarkers] = useState([]);
  const [circleOptions, setCircleOptions] = useState(null);

  // Handler for the SEARCH button click
  const handleSearch = () => {
    // Trim inputs
    const trimmedZip = zip.trim();
    const trimmedRadius = radius.trim();

    // Validation
    if (trimmedZip === '' || trimmedRadius === '') {
      setErrorMessage('Please enter both ZIP code and radius.');
      return;
    }

    if (!/^\d{5}$/.test(trimmedZip)) {
      setErrorMessage('Please enter a valid 5-digit ZIP code.');
      return;
    }

    const radiusValue = parseFloat(trimmedRadius);
    if (isNaN(radiusValue) || radiusValue <= 0) {
      setErrorMessage('Please enter a valid radius greater than 0.');
      return;
    }

    // Clear any previous error messages
    setErrorMessage('');

    // Update searchParams with the current zip and radius
    setSearchParams({ zip: trimmedZip, radius: radiusValue });
  };

  // Callback to handle data from Step2
  const handleDataFromStep2 = (data) => {
    console.log('Data received from Step2:', data);

    const { centerCoords, markers } = data;

    // Update map center
    setCenter(centerCoords);

    // Update map markers
    setMapMarkers(markers);

    // Update circle options
    setCircleOptions({
      center: centerCoords,
      radius: searchParams.radius * 1609.34, // Convert miles to meters
      options: {
        strokeColor: '#add8e6',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#add8e6',
        fillOpacity: 0.2,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        zIndex: 1,
        // For dotted circle
        strokeDashArray: [5, 5],
      },
    });
  };

  return (
    <MainLayout>
      {/* Header Section */}
      <div className="bg-transparent text-black p-4">
        <div className="container mx-auto text-center">
          <h1 className="text-2xl font-semibold">S.A.M. FOR CONTRACTORS (US ONLY)</h1>
          <p className="text-sm">Part of the Perfect Lead Locator. Not sold separately.</p>
        </div>
      </div>
      
      {/* Main Content */}
      <div className="container mx-auto px-4 py-8">
        {/* Input Section */}
        <div className="bg-white">
          <div className="flex flex-wrap">
            {/* Left Side: Input Form */}
            <div className="w-full lg:w-5/12 p-6 flex flex-col justify-center">
              <div className="space-y-4">
                {/* Step 1 Header */}
                <h2 className="text-xl font-semibold flex items-center text-black">
                  <span className="bg-blue-900 text-white rounded-full w-8 h-8 flex items-center justify-center mr-3">1</span>
                  Enter Your ZIP and Radius
                </h2>
                {/* Description */}
                <p className="text-gray-600 text-sm">
                  Submit the ZIP code you set on your Google Business Profile and the radius you're willing to travel.
                </p>
                <hr className="border-0" />
                {/* Error Message */}
                {errorMessage && (
                  <div className="bg-red-100 text-red-700 p-2 rounded text-sm">
                    {errorMessage}
                  </div>
                )}
                {/* Input Fields */}
                <div className="flex space-x-4">
                  {/* ZIP Code Input */}
                  <div className="w-2/5">
                    <label className="flex items-center mb-1 text-gray-700 text-sm">
                      <MapPin className="mr-2 text-blue-500" size={16} />
                      ZIP
                    </label>
                    <input
                      type="text"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                      placeholder="e.g., 68137"
                      className="w-full p-1 border border-gray-300 bg-white rounded text-sm"
                    />
                  </div>
                  {/* Radius Input */}
                  <div className="w-2/5">
                    <label className="flex items-center mb-1 text-gray-700 text-sm">
                      <svg 
                        className="mr-2 text-blue-500" 
                        width="16" 
                        height="16" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeDasharray="4 4"/>
                      </svg>
                      Radius (miles)
                    </label>
                    <input
                      type="number"
                      value={radius}
                      onChange={(e) => setRadius(e.target.value)}
                      placeholder="e.g., 30"
                      className="w-full p-1 border border-gray-300 bg-white rounded text-sm"
                    />
                  </div>
                  {/* Search Button */}
                  <div className="w-1/5 flex items-end">
                    <button 
                      onClick={handleSearch}
                      className="bg-amber-600 hover:bg-amber-700 text-white px-4 py-1 rounded w-full text-sm"
                    >
                      SEARCH →
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Right Side: Google Map */}
            <div className="w-full lg:w-1/2 relative" style={{ height: '500px' }}>
              <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  center={center}
                  zoom={10}
                >
                  {/* Circle representing the radius */}
                  {circleOptions && (
                    <Circle
                      center={circleOptions.center}
                      radius={circleOptions.radius}
                      options={circleOptions.options}
                    />
                  )}
                  {/* Markers */}
                  {mapMarkers.map((marker, index) => (
                    <Marker
                      key={index}
                      position={{ lat: marker.lat, lng: marker.lng }}
                      icon={
                        marker.color === 'red'
                          ? 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                          : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                      }
                    />
                  ))}
                </GoogleMap>
              </LoadScript>
              {/* Zoom Controls (Optional) */}
              {/* You can implement custom zoom controls here if needed */}
            </div>
          </div>
        </div>

        {/* Step 2 Component: Data Display */}
        {searchParams && (
          <div className="mt-8">
            <Step2 
              zip={searchParams.zip} 
              radius={searchParams.radius} 
              onDataLoaded={handleDataFromStep2}
            />
          </div>
        )}
        
        {/* Additional Components */}
        <Description />
        <Step3 />
        <Dictionary />
      </div>
    </MainLayout>
  );
};

export default SAMTool;
