import React from 'react';
import MainLayout from './MainLayout';

const SEORankings = () => {
  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">SEO Rankings</h1>
        <p>This is a mockup of the SEO Rankings component. Implement your SEO Rankings functionality here.</p>
      </div>
    </MainLayout>
  );
};

export default SEORankings;