import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from './Assets/logo-local-empire.png';

const EditAccountForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { account } = location.state || {};

  const [formData, setFormData] = useState({
    name: '',
    businessCategory: '',
    addressLine1: '',
    addressLine2: '',
    country: 'United States',
    state: '',
    city: '',
    zip: '',
    phoneNumber: '',
    websiteUrl: '',
    uniqueIdentifier: '',
    googleMapsPath: '',
  });

  const [error, setError] = useState('');

  // Load the account data into formData when component mounts
  useEffect(() => {
    if (account) {
      setFormData(account);
    } else {
      navigate('/accounts');
    }
  }, [account, navigate]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/api/accounts/${account._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.success) {
        navigate('/accounts');
      } else {
        console.error('Failed to update account:', data.error);
        setError('Failed to update account.');
      }
    } catch (error) {
      console.error('Error updating account:', error);
      setError('Error updating account.');
    }
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('authChange'));
    navigate('/login');
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-gradient-to-r from-[#141b30] to-[#0d4465] text-white p-4 flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Local Empire Logo" className="h-8 mr-4" />
        </div>
        <button className="px-4 py-2 text-white rounded" onClick={handleLogout}>
          Logout
        </button>
      </header>

      {/* Main Content */}
      <main className="flex-grow bg-gray-100 p-6">
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-6">Edit Account</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Error Message */}
            {error && <div className="text-red-500">{error}</div>}

            {/* Form Fields */}
            {/* Name and Business Category */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Location Name / Business Name: *
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Business Category: *
                </label>
                <select
                  name="businessCategory"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.businessCategory}
                  onChange={handleChange}
                >
                  <option value="">Select a category</option>
                  {/* Add your business categories here */}
                  <option value="Restaurant">Restaurant</option>
                  <option value="Retail">Retail</option>
                  <option value="Service">Service</option>
                  {/* ... */}
                </select>
              </div>
            </div>

            {/* Address Line 1 and Phone Number */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Address Line 1: *
                </label>
                <input
                  type="text"
                  name="addressLine1"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.addressLine1}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone Number: *
                </label>
                <input
                  type="tel"
                  name="phoneNumber"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Address Line 2 and Website URL */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Address Line 2:
                </label>
                <input
                  type="text"
                  name="addressLine2"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.addressLine2}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Website URL:
                </label>
                <input
                  type="url"
                  name="websiteUrl"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.websiteUrl}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Country, Zip, State, City */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Country: *
                </label>
                <input
                  type="text"
                  name="country"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.country}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Zip: *
                </label>
                <input
                  type="text"
                  name="zip"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.zip}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  State:
                </label>
                <input
                  type="text"
                  name="state"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.state}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  City:
                </label>
                <input
                  type="text"
                  name="city"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Unique Identifier */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Unique Identifier:
              </label>
              <input
                type="text"
                name="uniqueIdentifier"
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                value={formData.uniqueIdentifier}
                onChange={handleChange}
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-start">
              <button
                type="submit"
                className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Save Changes
              </button>
              <button
                type="button"
                className="ml-4 px-6 py-2 bg-gray-300 text-black rounded-md hover:bg-gray-400"
                onClick={() => navigate('/accounts')}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default EditAccountForm;