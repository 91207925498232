// src/components/Step2.jsx
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';

const Step2 = ({ zip, radius, onDataLoaded }) => {
  const [viewMode, setViewMode] = useState('zips');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size

  // Debounced search handler to minimize API calls
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setCurrentPage(1); // Reset to first page on new search
    }, 300),
    []
  );

  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Fetch data whenever zip, radius, sortConfig, or viewMode changes
  useEffect(() => {
    if (zip && radius) {
      fetchData();
    } else {
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip, radius, sortConfig, viewMode]); // Removed currentPage

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const { key, direction } = sortConfig;

      const params = {
        zip,
        radius,
        viewMode,
      };

      if (key) {
        params.sortKey = key;
        params.sortDirection = direction;
      }

      const response = await axios.get('/api/data', { params });

      setData(response.data.data);

      // Prepare data for the map
      const centerCoords = {
        lat: parseFloat(response.data.center.latitude),
        lng: parseFloat(response.data.center.longitude),
      };

      // Map over data to get markers
      const markers = response.data.data.map((item) => ({
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longitude),
        color: 'blue',
      }));

      // Add center marker (red pin)
      markers.push({
        lat: centerCoords.lat,
        lng: centerCoords.lng,
        color: 'red',
      });

      // Pass data back to SAMTool
      onDataLoaded({ centerCoords, markers });
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
      if (error.response) {
        setError(`Error: ${error.response.data.error || 'Failed to fetch data.'}`);
      } else if (error.request) {
        setError('No response from server. Please check your network connection.');
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Sorting and Searching logic
  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const key = sortConfig.key;
        const aValue = a[key];
        const bValue = b[key];

        // Handle missing values
        if (aValue === undefined || bValue === undefined) {
          return 0;
        }

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
        } else {
          const aStr = aValue.toString().toLowerCase();
          const bStr = bValue.toString().toLowerCase();
          if (aStr < bStr) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aStr > bStr) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    return sortedData.filter((item) =>
      viewMode === 'cities'
        ? item.city && item.city.toLowerCase().includes(searchTerm.toLowerCase())
        : item.zip && item.zip.toString().includes(searchTerm)
    );
  }, [sortedData, searchTerm, viewMode]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(filteredData.length / pageSize);
  }, [filteredData, pageSize]);

  // Get current page data
  const currentPageData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return filteredData.slice(startIndex, startIndex + pageSize);
  }, [filteredData, currentPage, pageSize]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Render table headers
  const renderTableHeaders = () => {
    const headers =
      viewMode === 'cities'
        ? [
            'City',
            'State',
            'County',
            'Population',
            'Average Income',
            'Percent Over $100k',
            'Population Under 18',
            'Number of Houses',
            'Average House Value',
            'Distance (miles)',
          ]
        : [
            'Zip',
            'State',
            'County',
            'City',
            'Population',
            'Average Income',
            'Percent Over $100k',
            'Population Under 18',
            'Number of Houses',
            'Average House Value',
            'Distance (miles)',
          ];

    const sortKeys =
      viewMode === 'cities'
        ? [
            'city',
            'state',
            'county',
            'population',
            'averageIncome',
            'percentOver100k',
            'populationUnder18',
            'numberOfHouses',
            'averageHouseValue',
            'distance',
          ]
        : [
            'zip',
            'state',
            'county',
            'city',
            'population',
            'averageIncome',
            'percentOver100k',
            'populationUnder18',
            'numberOfHouses',
            'averageHouseValue',
            'distance',
          ];

    return (
      <tr className="bg-gray-100">
        {headers.map((header, index) => (
          <th
            key={index}
            className="p-2 text-left cursor-pointer"
            onClick={() => requestSort(sortKeys[index])}
          >
            {header}
            {sortConfig.key === sortKeys[index] && (
              <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
            )}
          </th>
        ))}
      </tr>
    );
  };

  // Render table rows
  const renderTableRows = () => {
    if (currentPageData.length === 0) {
      return (
        <tr>
          <td colSpan={11} className="p-4 text-center">
            No data found.
          </td>
        </tr>
      );
    }

    return currentPageData.map((item, index) => {
      if (viewMode === 'cities') {
        return (
          <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
            <td className="p-2">{item.city}</td>
            <td className="p-2">{item.state}</td>
            <td className="p-2">{item.county}</td>
            <td className="p-2 text-right">{item.population?.toLocaleString()}</td>
            <td className="p-2 text-right">
              {item.averageIncome ? `$${item.averageIncome.toLocaleString()}` : 'N/A'}
            </td>
            <td className="p-2 text-right">
              {item.percentOver100k != null ? item.percentOver100k.toFixed(2) + '%' : 'N/A'}
            </td>
            <td className="p-2 text-right">
              {item.populationUnder18?.toLocaleString() || 'N/A'}
            </td>
            <td className="p-2 text-right">
              {item.numberOfHouses?.toLocaleString() || 'N/A'}
            </td>
            <td className="p-2 text-right">
              {item.averageHouseValue ? `$${item.averageHouseValue.toLocaleString()}` : 'N/A'}
            </td>
            <td className="p-2 text-right">{item.distance?.toFixed(1) || 'N/A'}</td>
          </tr>
        );
      } else {
        // viewMode === 'zips'
        return (
          <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
            <td className="p-2">{item.zip}</td>
            <td className="p-2">{item.state}</td>
            <td className="p-2">{item.county}</td>
            <td className="p-2">{item.city}</td>
            <td className="p-2 text-right">{item.population?.toLocaleString()}</td>
            <td className="p-2 text-right">
              {item.averageIncome ? `$${item.averageIncome.toLocaleString()}` : 'N/A'}
            </td>
            <td className="p-2 text-right">
              {item.percentOver100k != null ? item.percentOver100k.toFixed(2) + '%' : 'N/A'}
            </td>
            <td className="p-2 text-right">
              {item.populationUnder18?.toLocaleString() || 'N/A'}
            </td>
            <td className="p-2 text-right">
              {item.numberOfHouses?.toLocaleString() || 'N/A'}
            </td>
            <td className="p-2 text-right">
              {item.averageHouseValue ? `$${item.averageHouseValue.toLocaleString()}` : 'N/A'}
            </td>
            <td className="p-2 text-right">{item.distance?.toFixed(1) || 'N/A'}</td>
          </tr>
        );
      }
    });
  };

  // Handle view mode change and reset sortConfig
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    setSortConfig({ key: null, direction: 'ascending' });
    setCurrentPage(1); // Reset to first page on view mode change
  };

  // Placeholder export functions
  const handleExport = (type) => {
    // Implement export functionality here
    // You can send a request to the backend to generate the export file with all fields
    console.log(`Exporting data as ${type}`);
  };

  // Handle page size change
  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page on page size change
  };

  // Generate page numbers for pagination controls
  const generatePageNumbers = () => {
    const pages = [];
    const maxPageNumbersToShow = 5; // Adjust based on preference

    if (totalPages <= maxPageNumbersToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, '...', totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }

    return pages;
  };

  // Render pagination controls
  const renderPagination = () => {
    const pages = generatePageNumbers();

    return (
      <div className="flex justify-between items-center mt-4">
        {/* Page Size Selector */}
        <div>
          <label htmlFor="pageSize" className="mr-2">
            Show
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={handlePageSizeChange}
            className="px-2 py-1 border rounded"
          >
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>

        {/* Pagination Buttons */}
        <div className="flex space-x-2">
          {/* Previous Button */}
          <button
            className="px-3 py-1 bg-gray-200 rounded disabled:opacity-50"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          {/* Page Numbers */}
          {pages.map((page, index) => {
            if (page === '...') {
              return (
                <span key={index} className="px-3 py-1">
                  ...
                </span>
              );
            }

            return (
              <button
                key={index}
                className={`px-3 py-1 rounded ${
                  currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200'
                }`}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </button>
            );
          })}

          {/* Next Button */}
          <button
            className="px-3 py-1 bg-gray-200 rounded disabled:opacity-50"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      {/* Step 2 Header */}
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <span className="bg-blue-900 text-white rounded-full w-8 h-8 flex items-center justify-center mr-3">
          2
        </span>
        Explore Your Data
      </h2>

      {/* View Mode Buttons */}
      <div className="mb-4">
        <button
          className={`px-4 py-2 rounded-l-lg ${
            viewMode === 'cities' ? 'bg-gray-200' : 'bg-white'
          }`}
          onClick={() => handleViewModeChange('cities')}
        >
          Cities
        </button>
        <button
          className={`px-4 py-2 rounded-r-lg ${
            viewMode === 'zips' ? 'bg-gray-200' : 'bg-white'
          }`}
          onClick={() => handleViewModeChange('zips')}
        >
          ZIPs
        </button>
      </div>

      {/* Subheader */}
      <h3 className="text-lg font-semibold mb-2">
        All {viewMode === 'cities' ? 'cities' : 'ZIPs'} in the selected radius
      </h3>

      {/* Action Buttons and Search */}
      <div className="mb-4 flex justify-between items-center">
        <div>
          <button
            className="bg-gray-200 px-4 py-1 rounded mr-2"
            onClick={() => handleExport('copy')}
          >
            Copy
          </button>
          <button
            className="bg-gray-200 px-4 py-1 rounded mr-2"
            onClick={() => handleExport('excel')}
          >
            Excel
          </button>
          <button className="bg-gray-200 px-4 py-1 rounded" onClick={() => handleExport('pdf')}>
            PDF
          </button>
        </div>
        <input
          type="text"
          placeholder="Search"
          className="px-2 py-1 border rounded"
          onChange={(e) => debouncedSearch(e.target.value)}
        />
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>{renderTableHeaders()}</thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={11} className="p-4 text-center">
                  Loading...
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan={11} className="p-4 text-center text-red-500">
                  {error}
                </td>
              </tr>
            ) : (
              renderTableRows()
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {filteredData.length > pageSize && renderPagination()}

      {/* Information Text */}
      <div className="mt-2 text-sm text-gray-600">
        Showing {currentPageData.length} of {filteredData.length} results
      </div>
    </div>
  );
};

export default Step2;
