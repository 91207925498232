import React, { useState, useCallback, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { FaSearch, FaDownload, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import debounce from 'lodash/debounce';
import MainLayout from './MainLayout';

const AdWordsKeywordResearch = () => {
  const [keyword, setKeyword] = useState('');
  const [country, setCountry] = useState('');
  const [countryPredictions, setCountryPredictions] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const debouncedFetchPredictions = useCallback(
    debounce(async (input) => {
      if (input.length > 1) {
        try {
          const response = await fetch(`/api/adwords/keyword-research?input=${encodeURIComponent(input)}`);
          const data = await response.json();
          if (data.success) {
            setCountryPredictions(data.data);
          }
        } catch (err) {
          console.error('Error fetching country predictions:', err);
        }
      } else {
        setCountryPredictions([]);
      }
    }, 300),
    []
  );

  const handleCountryChange = (e) => {
    const value = e.target.value;
    setCountry(value);
    debouncedFetchPredictions(value);
  };

  const handleCountrySelect = (prediction) => {
    setCountry(prediction.description);
    setCountryPredictions([]);
  };

  const handleSearch = useCallback(async () => {
    if (!keyword || !country) {
      setError('Please enter both keyword and country.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch('/api/adwords/keyword-research', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ keyword, country }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch keyword data');
      }

      const data = await response.json();
      if (!data.success) {
        throw new Error(data.error || 'Unknown error occurred');
      }
      setResults(data.data);
    } catch (err) {
      console.error('Search error:', err);
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [keyword, country]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedResults = useMemo(() => {
    let sortableItems = [...results];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [results, sortConfig]);

  const columns = [
    { key: 'keyword', label: 'Keyword' },
    { key: 'searchVolume', label: 'Search Volume', format: value => value.toLocaleString() },
    { key: 'competition', label: 'Competition', format: value => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() },
    { key: 'minTopAdCost', label: 'Min. Est. Top Ad Cost', format: value => (value / 1_000_000 > 0 ? `$${(value / 1_000_000).toFixed(2)}` : '-') },
    { key: 'maxTopAdCost', label: 'Max. Est. Top Ad Cost', format: value => (value / 1_000_000 > 0 ? `$${(value / 1_000_000).toFixed(2)}` : '-') },
  ];

  const csvData = sortedResults.map(result => ({
    Keyword: result.keyword,
    'Search Volume': result.searchVolume,
    Competition: result.competition,
    'Minimum Estimated Top Ad Cost': (result.minTopAdCost / 1_000_000).toFixed(2),
    'Maximum Estimated Top Ad Cost': (result.maxTopAdCost / 1_000_000).toFixed(2),
  }));

  return (
    <MainLayout>
      <div className="flex flex-col min-h-screen bg-transparent">
        <div className="flex-grow container mx-auto px-4 py-8">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Advanced Keyword Research</h2>
          
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
              <input
                type="text"
                className="flex-grow p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#c1975f] focus:border-transparent text-gray-900 mb-4 md:mb-0"
                placeholder="Enter keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <div className="relative flex-grow">
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#c1975f] focus:border-transparent text-gray-900 mb-4 md:mb-0"
                  placeholder="Enter country"
                  value={country}
                  onChange={handleCountryChange}
                />
                {countryPredictions.length > 0 && (
                  <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto">
                    {countryPredictions.map((prediction, index) => (
                      <li
                        key={index}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleCountrySelect(prediction)}
                      >
                        {prediction.description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <button 
                className="w-full md:w-auto p-3 bg-[#c1975f] text-white rounded-md hover:bg-[#a17c4a] focus:outline-none focus:ring-2 focus:ring-[#c1975f] focus:ring-opacity-50 transition duration-200 ease-in-out flex items-center justify-center"
                onClick={handleSearch}
                disabled={loading}
              >
                <FaSearch className="mr-2" />
                {loading ? 'Searching...' : 'Search'}
              </button>
            </div>
            {error && (
              <div className="text-red-500 mt-4 text-center">{error}</div>
            )}
          </div>
          
          {sortedResults.length > 0 && (
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold text-gray-800">Search Results</h3>
                <CSVLink
                  data={csvData}
                  filename={"advanced_keyword_research.csv"}
                  className="px-4 py-2 bg-[#c1975f] text-white rounded-md hover:bg-[#a17c4a] focus:outline-none focus:ring-2 focus:ring-[#c1975f] focus:ring-opacity-50 transition duration-200 ease-in-out flex items-center text-sm"
                >
                  <FaDownload className="mr-2" />
                  Export CSV
                </CSVLink>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <thead>
                    <tr className="bg-gray-100">
                      {columns.map(({ key, label }) => (
                        <th
                          key={key}
                          className="px-4 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider border-b border-gray-200"
                        >
                          <button
                            onClick={() => handleSort(key)}
                            className="flex items-center focus:outline-none"
                          >
                            {label}
                            {sortConfig.key === key ? (
                              sortConfig.direction === 'ascending' ? (
                                <FaSortUp className="ml-1" />
                              ) : (
                                <FaSortDown className="ml-1" />
                              )
                            ) : (
                              <FaSort className="ml-1" />
                            )}
                          </button>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-gray-800">
                    {sortedResults.map((result, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                      >
                        {columns.map(({ key, format }) => (
                          <td
                            key={key}
                            className="px-4 py-4 whitespace-nowrap border-b border-gray-200"
                          >
                            {format ? format(result[key]) : result[key]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {sortedResults.length === 0 && !loading && !error && (
            <div className="text-gray-700 mt-4 text-center">
              No keyword ideas here yet.
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default AdWordsKeywordResearch;