import React, { useState, useRef } from 'react';
import { Search } from 'lucide-react';
import logo from './Assets/logo-local-empire.png';
import { useLoadScript } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';

const libraries = ['places'];

const AddAccountForm = ({ onAccountAdded }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    businessCategory: '',
    addressLine1: '',
    addressLine2: '',
    country: 'United States',
    state: '',
    city: '',
    zip: '',
    phoneNumber: '',
    websiteUrl: '',
    uniqueIdentifier: '',
    googleMapsPath: '',
    latitude: '',
    longitude: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  // Load the Google Maps script
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // Initialize services when the script is loaded
  if (isLoaded && !autocompleteService.current) {
    autocompleteService.current =
      new window.google.maps.places.AutocompleteService();
  }

  if (isLoaded && !placesService.current) {
    placesService.current = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );
  }

  // Function to extract data_id, latitude, and longitude from Google Maps URL
  const extractDataFromUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const pathname = decodeURIComponent(urlObj.pathname);

      // Match pattern to extract data_id, latitude, and longitude
      const dataMatch = pathname.match(/!1s([^!]+)!.*!3d([\d.-]+)!4d([\d.-]+)/);

      if (dataMatch && dataMatch.length === 4) {
        const data_id = dataMatch[1];
        const latitude = dataMatch[2];
        const longitude = dataMatch[3];
        return { data_id, latitude, longitude };
      } else {
        // Alternative pattern matching if the above doesn't match
        const altDataMatch = pathname.match(
          /!1s([^!]+)!.*!3d([\d.-]+)!.*!4d([\d.-]+)/
        );
        if (altDataMatch && altDataMatch.length === 4) {
          const data_id = altDataMatch[1];
          const latitude = altDataMatch[2];
          const longitude = altDataMatch[3];
          return { data_id, latitude, longitude };
        }
      }
    } catch (error) {
      console.error('Invalid URL:', error);
    }
    return null;
  };

  // Function to fetch place details from backend using SerpApi
  const fetchPlaceDetailsFromData = async (dataParam) => {
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('/api/places/fetch-details', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: dataParam }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to fetch place details:', errorData.error);
        setError(`Failed to fetch place details: ${errorData.error}`);
        setIsLoading(false);
        return;
      }

      const { data } = await response.json();
      console.log('Place Details:', data);

      // Update form data with fetched details
      setFormData((prevData) => ({
        ...prevData,
        name: data.name || '',
        addressLine1: data.addressLine1 || '',
        city: data.city || '',
        state: data.state || '',
        zip: data.zip || '',
        country: data.country || '',
        phoneNumber: data.phoneNumber || '',
        websiteUrl: data.websiteUrl || '',
        latitude: data.latitude || '',
        longitude: data.longitude || '',
        // Keep other fields as they are
      }));

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching place details:', error);
      setError('Error fetching place details.');
      setIsLoading(false);
    }
  };

  // Handle Google Maps URL input changes
  const handleGoogleMapsUrlChange = (e) => {
    const url = e.target.value;
    setFormData({ ...formData, googleMapsPath: url });

    if (url) {
      const data = extractDataFromUrl(url);
      if (data) {
        // Construct the data parameter as per SerpApi requirements
        const dataParam = `!4m5!3m4!1s${data.data_id}!8m2!3d${data.latitude}!4d${data.longitude}`;
        fetchPlaceDetailsFromData(dataParam);
      } else {
        setError('Invalid Google Maps URL. Could not extract necessary data.');
      }
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Remove token if not required
    // const token = localStorage.getItem('token');
    try {
      const response = await fetch('/api/accounts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include Authorization header if your backend requires authentication
          // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.success) {
        if (onAccountAdded) {
          onAccountAdded(data.data);
        }
        navigate('/accounts');
      } else {
        console.error('Failed to add account:', data.error);
        setError('Failed to add account.');
      }
    } catch (error) {
      console.error('Error adding account:', error);
      setError('Error adding account.');
    }
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('authChange'));
    navigate('/login');
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-gradient-to-r from-[#141b30] to-[#0d4465] text-white p-4 flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Local Empire Logo" className="h-8 mr-4" />
        </div>
        <button className="px-4 py-2 text-white rounded" onClick={handleLogout}>
          Logout
        </button>
      </header>

      {/* Main Content */}
      <main className="flex-grow bg-gray-100 p-6">
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-6">Add Account</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Form Fields */}
            {/* Google Maps URL Input */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Enter Google Maps URL:
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="googleMapsPath"
                  placeholder="https://www.google.com/maps/place/..."
                  className="w-full pl-8 pr-4 py-2 border border-gray-300 rounded-md"
                  value={formData.googleMapsPath}
                  onChange={handleGoogleMapsUrlChange}
                />
                <Search className="absolute left-2 top-2.5 h-5 w-5 text-gray-400" />
              </div>
            </div>

            {/* Loading Indicator */}
            {isLoading && (
              <div className="text-blue-500">Fetching business details...</div>
            )}

            {/* Error Message */}
            {error && <div className="text-red-500">{error}</div>}

            {/* Name and Business Category */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Location Name / Business Name: *
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Business Category: *
                </label>
                <select
                  name="businessCategory"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.businessCategory}
                  onChange={handleChange}
                >
                  <option value="">Select a category</option>
                  {/* Add your business categories here */}
                  <option value="Restaurant">Restaurant</option>
                  <option value="Retail">Retail</option>
                  <option value="Service">Service</option>
                  {/* ... */}
                </select>
              </div>
            </div>

            {/* Address Line 1 and Phone Number */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Address Line 1: *
                </label>
                <input
                  type="text"
                  name="addressLine1"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.addressLine1}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone Number: *
                </label>
                <input
                  type="tel"
                  name="phoneNumber"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Address Line 2 and Website URL */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Address Line 2:
                </label>
                <input
                  type="text"
                  name="addressLine2"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.addressLine2}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Website URL:
                </label>
                <input
                  type="url"
                  name="websiteUrl"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.websiteUrl}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Country, Zip, State, City */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Country: *
                </label>
                <input
                  type="text"
                  name="country"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.country}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Zip: *
                </label>
                <input
                  type="text"
                  name="zip"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.zip}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  State:
                </label>
                <input
                  type="text"
                  name="state"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.state}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  City:
                </label>
                <input
                  type="text"
                  name="city"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Unique Identifier */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Unique Identifier:
              </label>
              <input
                type="text"
                name="uniqueIdentifier"
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                value={formData.uniqueIdentifier}
                onChange={handleChange}
              />
            </div>

            {/* Latitude and Longitude */}
            {/* Optional: Display latitude and longitude fields (read-only) */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Latitude:
                </label>
                <input
                  type="text"
                  name="latitude"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.latitude}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Longitude:
                </label>
                <input
                  type="text"
                  name="longitude"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={formData.longitude}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>

            {/* Submit Button */}
            <div className="flex justify-start">
              <button
                type="submit"
                className="px-6 py-2 bg-[#c1975f] text-white rounded-md hover:bg-[#a17c4a]"
              >
                Add Account
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default AddAccountForm;