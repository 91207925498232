import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  FaTachometerAlt,
  FaToolbox,
  FaClipboardList,
  FaMapMarkerAlt,
  FaChartLine,
  FaSignOutAlt,
  FaUsers,
} from 'react-icons/fa';
import logo from './Assets/logo-local-empire.png';

const Topbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('authChange'));
    navigate('/login');
  };

  return (
    <div className="bg-gradient-to-r from-[#141b30] to-[#0d4465] text-white p-2 md:p-4">
      <div className="container mx-auto flex flex-wrap justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Local Empire Logo" className="h-6 md:h-8" />
        </div>
        <nav className="flex flex-wrap space-x-2 md:space-x-4 text-sm md:text-base">
          <TopbarLink to="/accounts" icon={FaUsers} label="Accounts" />
          <TopbarLink to="/dashboard" icon={FaTachometerAlt} label="Dashboard" />
          <TopbarLink to="/sam-tool" icon={FaToolbox} label="SAM" />
          <TopbarLink to="/keyword-planner" icon={FaClipboardList} label="Keywords" />
          <TopbarLink to="/local-rankings" icon={FaMapMarkerAlt} label="Local" />
          <TopbarLink to="/seo-rankings" icon={FaChartLine} label="SEO Rankings" />
          <button
            onClick={handleLogout}
            className="flex items-center px-2 py-1 md:px-3 md:py-2 rounded hover:bg-blue-700 transition-colors duration-200"
          >
            <FaSignOutAlt className="mr-1 md:mr-2 text-sm md:text-base" />
            <span className="hidden md:inline">Logout</span>
          </button>
        </nav>
      </div>
    </div>
  );
};

const TopbarLink = ({ to, icon: Icon, label }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `flex items-center px-2 py-1 md:px-3 md:py-2 rounded hover:bg-blue-700 transition-colors duration-200 ${
        isActive ? 'bg-blue-900' : ''
      }`
    }
  >
    <Icon className="mr-1 md:mr-2 text-sm md:text-base" />
    <span className="hidden md:inline">{label}</span>
  </NavLink>
);

export default Topbar;