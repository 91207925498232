import React from 'react';

const Description = () => {
  const fields = [
    { name: 'Zip', description: 'Generalized areal representations of United States Postal Service (USPS) ZIP Code service areas.' },
    { name: 'S', description: 'Two letter state name abbreviation' },
    { name: 'County', description: 'Name of Primary County of the ZIP Code' },
    { name: 'City', description: 'Alias name of the city of the ZIP Code' },
    { name: 'Pop.', description: 'Total population of the City/ZIP code' },
    { name: 'Avg. Inc.', description: 'Total households Mean(Average) household income (dollars)' },
    { name: '> $100k', description: 'Percentage of population earning six figures and more' },
    { name: 'Pop. >', description: 'Total population earning six figures and more' },
    { name: '# Hses', description: 'Total housing units of the City/ZIP code' },
    { name: 'Avg. Hse', description: 'Estimate VALUE Owner-occupied units Median (dollars)' },
    { name: 'Dist.', description: 'Distance from given ZIP code in miles' },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
      <h3 className="text-lg font-semibold mb-4">Table showing zips with a population over 500. Data sourced & compiled from the U.S. Census Bureau.</h3>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-800 text-white">
              <th className="p-2 text-left">Field Name</th>
              <th className="p-2 text-left">Description</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                <td className="p-2 font-medium">{field.name}</td>
                <td className="p-2">{field.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Description;