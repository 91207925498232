import React, { useState } from 'react';
import { MapPin } from 'lucide-react';

const Step3 = () => {
  const [zip, setZip] = useState('');
  const [radius, setRadius] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(['General', 'Income', 'Housing']);
  const [grouping, setGrouping] = useState('city');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dataOptions = [
    'General', 'Sex, Age, and Civil Status', 'Veterans and Disability', 'Race', 'Income', 'Housing',
    'House Unit Type', 'House Value', 'House Built Date', 'House Rooms', 'House Move In Year', 'Vehicles',
    'House Heating', 'House Cohabitating Status', 'Occupation', 'Industry', 'Employment', 'Education'
  ];

  const handleOptionChange = (option) => {
    setSelectedOptions(prev => 
      prev.includes(option) ? prev.filter(item => item !== option) : [...prev, option]
    );
  };

  const handleDownload = async () => {
    setError(null);

    // Validate inputs
    if (!zip || !radius || selectedOptions.length === 0) {
      setError('Please enter ZIP code, radius, and select at least one data option.');
      return;
    }

    try {
      setLoading(true);

      const params = new URLSearchParams();
      params.append('zip', zip);
      params.append('radius', radius);
      params.append('grouping', grouping);

      selectedOptions.forEach(option => params.append('selectedOptions', option));

      const response = await fetch(`/api/data-download?${params.toString()}`);

      if (!response.ok) {
        // Handle errors
        const errorData = await response.json();
        setError(errorData.error || 'An error occurred while downloading the data.');
        setLoading(false);
        return;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      setLoading(false);
    } catch (err) {
      console.error('Error downloading data:', err);
      setError('An error occurred while downloading the data.');
      setLoading(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <span className="bg-blue-900 text-white rounded-full w-8 h-8 flex items-center justify-center mr-3">3</span>
        Advanced Data
      </h2>
      <p className="mb-4 text-sm text-gray-600">
        If you want to step beyond the basic data, below you can download extended datasets with 165 data points per ZIP.
      </p>
      <div className="flex space-x-4 mb-4">
        <div className="w-1/2">
          <label className="flex items-center mb-1 text-gray-700 text-sm">
            <MapPin className="mr-2 text-blue-500" size={16} />
            ZIP
          </label>
          <input
            type="text"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            placeholder="68137"
            className="w-full p-2 border border-gray-300 rounded text-sm"
          />
        </div>
        <div className="w-1/2">
          <label className="flex items-center mb-1 text-gray-700 text-sm">
            <svg className="mr-2 text-blue-500" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeDasharray="4 4"/>
            </svg>
            Radius
          </label>
          <input
            type="number"
            value={radius}
            onChange={(e) => setRadius(e.target.value)}
            placeholder="30"
            className="w-full p-2 border border-gray-300 rounded text-sm"
          />
        </div>
      </div>
      <div className="mb-4">
        {dataOptions.map((option, index) => (
          <label key={index} className="inline-flex items-center mr-4 mb-2">
            <input
              type="checkbox"
              checked={selectedOptions.includes(option)}
              onChange={() => handleOptionChange(option)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2 text-sm text-gray-700">{option}</span>
          </label>
        ))}
      </div>
      <div className="mb-4">
        <label className="inline-flex items-center mr-4">
          <input
            type="radio"
            checked={grouping === 'city'}
            onChange={() => setGrouping('city')}
            className="form-radio h-4 w-4 text-blue-600"
          />
          <span className="ml-2 text-sm text-gray-700">Group by City</span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            checked={grouping === 'noGrouping'}
            onChange={() => setGrouping('noGrouping')}
            className="form-radio h-4 w-4 text-blue-600"
          />
          <span className="ml-2 text-sm text-gray-700">No Grouping, all ZIPs</span>
        </label>
      </div>
      {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
      <button
        onClick={handleDownload}
        disabled={loading}
        className={`bg-amber-600 hover:bg-amber-700 text-white px-4 py-2 rounded text-sm ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {loading ? 'Downloading...' : 'DOWNLOAD DATA'}
      </button>
      <p className="mt-4 text-xs text-gray-500">
        Maximum radius is 50 miles. The data is downloaded as a CSV file. You can import CSV files in Google Sheets, or open the file with Microsoft Excel. The data includes multiple datasets so there may be minor inaccuracies. Data sourced & compiled from the U.S. Census Bureau and ACS 5-Year Data. If you need more information, check out the data visualizer on census.gov
      </p>
    </div>
  );
};

export default Step3;
